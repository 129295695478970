import {
    setPreviousPath,
    setState, getRedirectUri
} from './storage';

export const login = ({locationPostLogin=null, redirectUri=null, loginHost='https://beta-login.ekultur.org'}) => {

    const state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const callbackUrl = redirectUri ? redirectUri : getRedirectUri();
    setPreviousPath(filterLocation(locationPostLogin));
    setState(state);
    const loginUrl = `${loginHost}/oauth2/authorize?redirect_uri=${callbackUrl}&state=${state}`;
    window.location = loginUrl;
    return null
};

const filterLocation = locationPostLogin => locationPostLogin ? filterLocationBlacklist(locationPostLogin) : '/';

const filterLocationBlacklist = prevLocation => {
    switch(prevLocation) {
        case '/login':
            return '/';
        case '/logout':
            return '/';
        case '/oauth2/callback':
            return '/';
        default:
            return prevLocation
    }
};
