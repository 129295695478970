export const getState = () => sessionStorage.getItem('state');
export const setState = state => sessionStorage.setItem('state', state);

export const getPreviousPath = () =>  sessionStorage.getItem('previousPath');
export const setPreviousPath = previousPath => sessionStorage.setItem('previousPath', previousPath);

export const  getRedirectUri = () => {
    let url = `${window.location.protocol}//${window.location.hostname}`;

    if(window.location.port) {
        url = `${url}:${window.location.port}`
    }

    return `${url}/oauth2/callback`;
};

export const cleanStorage = () => {
    sessionStorage.removeItem('state');
    sessionStorage.removeItem('previousPath');
};

export const setFutureRefresh = id => localStorage.setItem('kit.future_refresh', id.toString())
export const getFutureRefresh = () => parseInt(localStorage.getItem('kit.future_refresh'))
export const removeFutureRefresh = () => localStorage.removeItem('kit.future_refresh');

export const setAccessToken = accessToken => localStorage.setItem('kit.access_token', accessToken);
export const removeAccessToken = () => localStorage.removeItem('kit.access_token')

export const setIdToken = idToken => localStorage.setItem('kit.id_token', idToken);
export const removeIdToken = () => localStorage.removeItem('kit.id_token')