import {cleanStorage, getFutureRefresh, removeAccessToken, removeFutureRefresh, removeIdToken} from "./storage";

export const logout = ({signal=null, onLogout=null, apiGateway=null}) => {
    const abortController = new AbortController();

    let options = {
        method: 'POST',
        credentials: 'include'

    };

    if(signal) {
        options.signal = abortController.signal
    }

    const gatewayHost = apiGateway || getApiGateway()

    fetch(`${gatewayHost}/auths/oauth2/logout`, options)
        .then(() => {
            cleanStorage();
            removeAccessToken();
            removeIdToken()
            clearTimeout(getFutureRefresh());
            removeFutureRefresh();
            if(onLogout) {
                onLogout()
            }
        });
};

const getApiGateway = () => {
    if(window._env_ && window._env_.REACT_APP_API_GATEWAY) {
        return window._env_.REACT_APP_API_GATEWAY;
    }
    return process.env.REACT_APP_API_GATEWAY || 'https://beta-apigateway.dimu.org';
}